import APIService from "./APIService";
import axios from "axios";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export default class LoginService extends APIService {
  async start() {
    const response = await axios(
      {
        method : "get",
        url : process.env.VUE_APP_API_URL + 'sanctum/csrf-cookie'
      }
    );
  }
  
  async check() {
    try {
      const response = await axios({
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'login-check'
      });
      
      return response.data;
    }
    catch (e) {return false;}
  }
  
  
  async login(email, password) {
    try {
      const response = await this.send(
        'post', 'login', 
        {
          email: email,
          password
        },
      );

      if (response[0]) {
        return true;
      }
    }
    catch (e) {
      console.log(e);
      return false;
    }
  }

  async logout() {
    await this.send("get", "logout");
  }

  async recovery(email) {
    try {
      const response = await this.send('post', "password-recovery", { email : email});
      return response;
    }
    catch (e) {
      return false;
    }
  }
  
  async updatePassword(data) {
    try {
      const response = await this.send('post', "password-reset", data);
      return response;
    }
    catch (e) {
      return e;
    }
  }
  
  async getEmail(data) {
    try {
      const response = await this.send('post', "password-reset/email", data);
      return response;
    }
    catch (e) {
      return e;
    }
  }
}